<div class="edit-dialog">
  <div class="table-header d-flex flex-row justify-content-between align-items-center h-20">
    <h2 class="m-4">
      Pulpo User
    </h2>
  </div>

  <mat-dialog-content>
    <form *ngIf="editForm" class="mt-2 w-100 bordered corners" name="editForm" novalidate [formGroup]="editForm"
      noValidate>
      <div class="row w-100">
        <div class="col-sm-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>ID</mat-label>
            <input required matInput type="text" formControlName="id" readonly [disabled]="true" class="text-muted" />
          </mat-form-field>
        </div>

        <div class="col-sm-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email</mat-label>
            <input required matInput type="text" formControlName="email" />
          </mat-form-field>
        </div>

        <div class="col-sm-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Pseudo</mat-label>
            <input matInput type="text" formControlName="pseudo" />
          </mat-form-field>
        </div>

        <div class="col-sm-3">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Téléphone</mat-label>
            <input matInput type="text" formControlName="phone" />
          </mat-form-field>
        </div>
      </div>

      <div class="row w-100">
        <div class="col-sm-6">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Nom</mat-label>
            <input matInput type="text" formControlName="lastName" />
          </mat-form-field>
        </div>

        <div class="col-sm-6">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Prénom</mat-label>
            <input matInput type="text" formControlName="firstName" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-2 justify-content-center">
        <div class="col-sm-2">
          <button mat-raised-button class="admin" [disabled]="editForm.invalid || isSaving || !editForm.dirty"
            (click)="onSubmitForm()">
            <mat-icon>save</mat-icon>
          </button>
        </div>
        <div class="col-sm-2">
          <button mat-raised-button class="delete" *ngIf="!this.forceDelete" (click)="askDelete()">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-raised-button class="force-delete" *ngIf="this.forceDelete" (click)="confirmDeletion()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="row mt-2 justify-content-center" style="color:red; font-weight: bold;" *ngIf="this.forceDelete">
        ATTENTION : Vous avez demandé la suppression de l'utilisateur. Cliquer de nouveau pour confirmer.
      </div>
    </form>
  </mat-dialog-content>
</div>