import { Inject, Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { APP_CONFIG } from '../../../../app-config';
import { AuthService, StorageService } from '@pulpo/pulpo-api';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  carRentalApiLocalUrl = 'http://localhost:8083';
  bookingApiLocalUrl = 'http://localhost:8086';
  insuranceApiLocalUrl = 'http://localhost:8084';
  accommodationApiLocalUrl = 'http://localhost:8088';
  batchApiLocalUrl = 'http://localhost:8082';
  flightApiLocalUrl = 'http://localhost:4300';
  optionApiLocalUrl = 'http://localhost:8087';
  ordoApiLocalUrl = 'http://localhost:8085';
  productApiLocalUrl = 'http://localhost:8091';
  cacheApiLocalUrl = 'http://localhost:8092';
  dataStorageApiLocalUrl = 'http://localhost:8093';
  constructor(
    @Inject(APP_CONFIG) private environment: any,
    private storageService: StorageService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !request ||
      !request.url ||
      request.url.endsWith('refreshtoken') ||
      (request.url.startsWith('http') &&
        !(
          (this.environment.apiUrl &&
            request.url.startsWith(this.environment.apiUrl)) ||
          ((request.url.startsWith(this.carRentalApiLocalUrl) ||
            request.url.startsWith(this.bookingApiLocalUrl) ||
            request.url.startsWith(this.insuranceApiLocalUrl) ||
            request.url.startsWith(this.accommodationApiLocalUrl) ||
            request.url.startsWith(this.flightApiLocalUrl) ||
            request.url.startsWith(this.ordoApiLocalUrl) ||
            request.url.startsWith(this.batchApiLocalUrl) ||
            request.url.startsWith(this.optionApiLocalUrl) ||
            request.url.startsWith(this.cacheApiLocalUrl) ||
            request.url.startsWith(this.dataStorageApiLocalUrl) ||
            request.url.startsWith(this.productApiLocalUrl)) &&
            this.environment.name === 'localDev')
        ))
    ) {
      return next.handle(request);
    }
    const token = this.storageService.getToken();

    const expirationTime = this.storageService.getTokenExpirationTime();
    if (token) {
      if (
        !expirationTime ||
        expirationTime < new Date().getTime() / 1000 + 120
      ) {
        return this.authService.refreshToken().pipe(
          switchMap((token: any) => {
            const cloned = request.clone({
              setHeaders: {
                Authorization: 'Bearer ' + token,
              },
            });
            return next.handle(cloned);
          })
        );
      } else {
        const cloned = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });
        return next.handle(cloned);
      }
    } else {
      return next.handle(request);
    }
  }
}
